import React, { useState } from 'react';
import { Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Divider, Box, ListItemIcon } from '@mui/material';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import Person from '@mui/icons-material/Person';
import PersonAdd from '@mui/icons-material/PersonAdd';
import AddCard from '@mui/icons-material/AddCard';
import Payment from '@mui/icons-material/Payment';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Logout from '@mui/icons-material/Logout';
import StudentManagement from './pages/StudentManagement';
import Payments from './pages/Payments';
import PaymentsStudent from './pages/PaymentsStudent';
import PaymentForm from './components/PaymentForm';
import Settlements from './pages/Settlements';
import Login from './pages/Login';
import { isAuthenticated, removeToken } from './utils/auth';

const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    removeToken();
    navigate('/login');
  };


  const menuItems = [
    { text: 'Students', path: '/students', icon: <Person /> },
    { text: 'Add Student', path: '/add-student', icon: <PersonAdd /> },
    { text: 'Payments', path: '/payments', icon: <Payment /> },
    { text: 'Add Payment', path: '/add-payment', icon: <AddCard />},
    { text: 'Settlements', path: '/settlements', icon: <AddShoppingCart /> },
  ];

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            WuDao Management
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            {menuItems.map((item, index) => (
              <ListItem button component={Link} to={item.path} key={index}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
            <Divider />
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Stack style={{ marginTop: '64px', paddingTop: '16px' }} spacing={2}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              isAuthenticated() ? <StudentManagement /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <Payments />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/payments"
            element={
              isAuthenticated() ? (
                <Payments />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/add-payment"
            element={
              isAuthenticated() ? (
                <PaymentForm  />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/add-payment/student/:id"
            element={
              isAuthenticated() ? (
                <PaymentForm  />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/edit-payment/:idPayment"
            element={
              isAuthenticated() ? (
                <PaymentForm  />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/payments/student/:id"
            element={
              isAuthenticated() ? (
                <PaymentsStudent  />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/settlements"
            element={
              isAuthenticated() ? (
                <Settlements />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Stack>
    </div>
  );
};

export default App;
