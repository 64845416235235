import React from 'react';
import { Routes, Route } from 'react-router-dom';
import StudentForm from '../pages/StudentForm';
import Students from './Students';

const StudentManagement = () => {
  
  return (
    <Routes>
      <Route path="/students" element={<Students />} />
      <Route path="/add-student" element={<StudentForm />} />
      <Route path="/edit-student/:id" element={<StudentForm />} />
    </Routes>
  );
};

export default StudentManagement;
