// src/pages/StudentForm.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../utils/api';
import { Container, Paper, Typography, TextField, Button, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

const StudentForm = ({studentId = null}) => {
  const [student, setStudent] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    address: '',
    phone: '',
    mainLocation: '',
    photo: '',
  });

  const [wait, setWait] = useState(false);

  const [previewPhoto, setPreviewPhoto] = useState(null); // For previewing the existing or new photo

  const navigate = useNavigate();
  let { id } = useParams();

  if (studentId > 0) {
    id = studentId;
  }

  useEffect(() => {
    if (id) {
      api.get(`/students/${id}`).then(response => {
        setStudent(response.data);
        if (response.data.photo) {
          // eslint-disable-next-line no-undef
          setPreviewPhoto(`${process.env.REACT_APP_API_BASE_URL}/uploads/photos/${response.data.photo}`);
        }
      });
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWait(true);

    const formData = new FormData();

    // Append non-file fields
    formData.append('firstName', student.firstName);
    formData.append('lastName', student.lastName);
    formData.append('gender', student.gender);
    student.dateOfBirth && formData.append('dateOfBirth', student.dateOfBirth.substring(0, 10));
    formData.append('address', student.address);
    formData.append('phone', student.phone);
    formData.append('mainLocation', student.mainLocation);

    // Append the photo file only if it's selected
    if (student.photo) {
      formData.append('photo', student.photo);
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      if (id) {
        await api.post(`/students/${id}/edit`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        navigate(`/students`);
      } else {
        const resp = await api.post('/students/new',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        id = resp.data.id;
        navigate(`/add-payment/student/${id}`);
      }
    } catch (error) {
      console.error('Error saving student:', error);
    }
  };

  const handleChange = (e) => {
    setStudent({
      ...student,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setStudent({
      ...student,
      photo: file, // Store the file object
    });
    setPreviewPhoto(URL.createObjectURL(file)); // Show preview of the selected photo
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '1em' }}>
        <Typography variant="h5" gutterBottom>
          {id ? 'Edit Student' : 'Add Student'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}> 
            <Grid item xs={12}>
              <TextField
                label="First Name"
                name="firstName"
                required
                fullWidth
                value={student.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Name"
                name="lastName"
                required
                fullWidth
                value={student.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Gender"
                name="gender"
                select
                fullWidth
                value={student.gender}
                onChange={handleChange} 
              >
                 <MenuItem key={"Male"} value={"Male"}>
                  {"Male"}
                </MenuItem>
                 <MenuItem key={"Female"} value={"Female"}>
                  {"Female"}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
            <TextField
                label="Date of Birth (mm/dd/yyyy)"
                name="dateOfBirth"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={student.dateOfBirth.substring(0, 10)}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                fullWidth
                value={student.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                name="phone"
                fullWidth
                value={student.phone}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Main Location"
                name="mainLocation"
                fullWidth
                select
                value={student.mainLocation}
                onChange={handleChange}
              >
                <MenuItem key={"Dristor"} value={"Dristor"}>{"Dristor"}</MenuItem>
                <MenuItem key={"Militari"} value={"Militari"}>{"Militari"}</MenuItem>
                <MenuItem key={"Arcul de Triumf"} value={"Arcul de Triumf"}>{"Arcul de Triumf"}</MenuItem>
              </TextField>
            </Grid>
            {previewPhoto && (
              <Grid item xs={12}>
                <img src={previewPhoto} alt="Student" style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button variant="contained" component="label">
                Upload Photo / Take Photo
                <input type="file" accept="image/*" capture="camera" hidden onChange={handleFileChange} />
              </Button>
            </Grid>
            <Grid item xs={12}>
              {wait ? (
                <CircularProgress />
              ) : (
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  {id ? 'Update Student' : 'Add Student'}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default StudentForm;
