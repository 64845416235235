import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
    Paper,
    Typography,
    Button,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import api from '../utils/api';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const PaymentsStudent = () => {
    const { id } = useParams();
    const [payments, setPayments] = useState([]);
    const [student, setStudent] = useState([]);

    useEffect(() => {
        if (id) {
            api.get(`/students/${id}`).then(response => {
                setStudent(response.data);
            });
        }
    }, [id]);

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const response = await api.get(`/payments/student/${id}`);
                setPayments(response.data);
            } catch (error) {
                console.error('Error fetching students:', error);
            }
        };

        fetchPayments();
    }, [id]);

    return (
        <Container>
            <Paper elevation={3} style={{ padding: '1em', marginBottom: '2em' }}>
                <Typography variant="h5" gutterBottom>
                    Payments for {student.firstName} {student.lastName}
                </Typography>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Representing</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.map((_el, key) =>
                            <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{dayjs(_el.date).format('YYYY-MM-DD')}</TableCell>
                                <TableCell align="right">{_el.amount}</TableCell>
                                <TableCell align="right">{_el.representing}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
              </Table>
              <Button variant="contained" color="primary" component={Link} to={`/add-payment/student/${student.id}`} fullWidth>
                Add Payment
            </Button>
            </Paper>
        </Container>
    );
}

export default PaymentsStudent;