// src/pages/Settlements.js

import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, IconButton, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Delete } from '@mui/icons-material';
import api from '../utils/api';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import ConfirmDialog from '../components/ConfirmDialog';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));
const SmallItem = styled('div')(({ theme }) => ({
  fontSize: '10px'
}));

const Settlements = () => {
  const [settlements, setSettlements] = useState([]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const response = await api.get('/settlements/');
        setSettlements(response.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchSettlements();
  }, []);

  settlements.forEach(settlement => {
    settlement.decodedRepresenting = JSON.parse(settlement.representing);
    settlement.lastDate = settlement.decodedRepresenting[0].date;
    settlement.firstDate = settlement.decodedRepresenting[settlement.decodedRepresenting.length -1].date;
  })

  const handleDeleteClick = (e, payment) => {
    e.stopPropagation();
    setIdToDelete(payment);
    setConfirmOpen(true);
  };

  const deleteSettlement = async () => {
    if (idToDelete) {
      try {
        await api.post(`/settlements/${idToDelete}/delete`);
        setSettlements(settlements.filter(settlement => settlement.id !== idToDelete));
        setConfirmOpen(false);
      } catch (error) {
        console.error('Error deleting student:', error);
      }
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '1em' }}>
        <Typography variant="h5" gutterBottom>
          Settlements
        </Typography>
        {settlements.map((settlement, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}><b>{`${dayjs(settlement.date).format('YYYY-MM-DD')}`}</b> &nbsp;
                <SmallItem>
                (
                  {`${dayjs(settlement.firstDate).format('YYYY-MM-DD')}`} &gt; &nbsp;
                  {`${dayjs(settlement.lastDate).format('YYYY-MM-DD')}`}
                )</SmallItem>
                </Grid>
                <Grid item xs={4}>
                <Item><b>{settlement.amount}</b></Item>
                </Grid>
                <Grid item xs={1}>
                <IconButton onClick={(e) => handleDeleteClick(e, settlement.id)}>
                  <Delete />
                </IconButton>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Table aria-label="simple table">
                <TableBody>
                  {settlement.decodedRepresenting.map((_el, key) =>
                    <TableRow
                      key={key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {_el.name}
                      </TableCell>
                      <TableCell align="right">{_el.representing}</TableCell>
                      <TableCell align="right">{dayjs(_el.date).format('YYYY-MM-DD')}</TableCell>
                      <TableCell align="right">{_el.amount}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>

      <ConfirmDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={deleteSettlement}
        title="Delete Settlement"
        description="Are you sure you want to delete this settlement?"
      />
    </Container>
  );
};

export default Settlements;
